import React, { useEffect, useState } from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import TableSortLabel from '@mui/material/TableSortLabel';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import loading from "../assets/loadingNew.gif"
import { set } from 'mongoose';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "95vw",
    height: "80vh",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};



export default function BulkModal({ formattedData, changeStatus }) {
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0)
    const [filteredData, setFilteredData] = useState([])



  useEffect(() => {
    const fd = formattedData.filter((elem) => elem.status !== "complete")
    setFilteredData(fd)
    setIndex(0)
    console.log(fd)
  }, [open])



 


    const handleOpen = () => {

        setOpen(true);
   
    };

    const handleClose = () => setOpen(false);


    const handleIndexChange = () => {
        const sku = filteredData[index].combinedSku
        changeStatus(sku)
        setIndex(() => index + 1)

    }




      return (
        <div style={{  }}>
            <Button style={{height:"5vh", width:"5vw", padding:"40px"}} component="label" variant="contained" onClick={handleOpen}>Create</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

            <Box sx={style}>

            {((index + 1) > filteredData.length) ?

                <div>
                    
                    <h3>Finished</h3>
                 
                    <Button onClick={handleClose}>Finish</Button>

                </div>

        
                :
                <div>
                    <h3>{(index + 1)}/{filteredData.length}</h3>

                    <Button onClick={handleIndexChange}>Create</Button>
                </div>
    
               
            
                }

               
                  
            </Box>
                </Modal>

        </div>
    )




}