import React, { useEffect, useState } from 'react';

import {
    Routes,
    Route,
  } from "react-router-dom";

import Home from "../views/Home"
import ProtectedRoute from "../components/ProtectedRoute";
import Activity from "../views/Activity";
import MainNav from "./MainNav";
import Analytics from "../views/Analytics";
import Fulfillment from "../views/Fulfillment";
import Inventory from "../views/Inventory";
import Sandbox from "../views/SandBox"
import CatalogParent from '../views/CatalogParent';
import PackCreatorMain from '../views/PackCreatorMain';
import YarnParent from '../views/YarnParent';
import ColorChanger from '../views/ColorChanger';
import Alerts from '../views/Alerts';
import AnalyticsGrid from '../views/AnalyticsGrid';
import Labels from '../views/Labels';
import LabelsParent from '../views/LabelsParent';
import Override from '../views/Override';
import BIParent from '../views/BIParent';
import Adjustments from '../views/Adjustments';
import KRInventory from '../views/KRInventory';
import BomMain from '../views/BomMain';
import UPC from '../views/UPC';
import BulkCreation from '../views/BulkCreation';




export default function NavRoutes( ) {


    return (
        <>
        <MainNav  />
        <Routes>
            <Route path="/" element={<ProtectedRoute component={Home} />}></Route>
            <Route path="/activity" element={<ProtectedRoute component={Activity} />}></Route>
            <Route path="/analytics" element={<ProtectedRoute component={Analytics} />}></Route>
            <Route path="/fulfillment" element={<ProtectedRoute component={Fulfillment} />}></Route>
            <Route path="/inventory" element={<ProtectedRoute component={Inventory} />}></Route>
            <Route path="/packcreator" element={<ProtectedRoute component={PackCreatorMain} />}></Route>
            <Route path="/catalog" element={<ProtectedRoute component={CatalogParent} />}></Route>
            <Route path="/yarn" element={<ProtectedRoute component={YarnParent} />}></Route>
            <Route path="/colorchanger" element={<ProtectedRoute component={ColorChanger} />}></Route>
            <Route path="/alerts" element={<ProtectedRoute component={Alerts} />}></Route>
            <Route path="/analyticsgrid" element={<ProtectedRoute component={AnalyticsGrid} />}></Route>
            <Route path="/labels" element={<ProtectedRoute component={LabelsParent} />}></Route>
            <Route path="/override" element={<ProtectedRoute component={Override} />}></Route>
            <Route path="/bundleimages" element={<ProtectedRoute component={BIParent} />}></Route>
            <Route path="/adjustments" element={<ProtectedRoute component={Adjustments} />}></Route>
            <Route path="/kr" element={<ProtectedRoute component={KRInventory} />}></Route>
            <Route path="/bom" element={<ProtectedRoute component={BomMain} />}></Route>
            <Route path="/upc" element={<ProtectedRoute component={UPC} />}></Route>
            <Route path="/bulkcreation" element={<ProtectedRoute component={BulkCreation} />}></Route>
            <Route path="/sandbox" element={<Sandbox />}></Route>

        </Routes>
        </>
    )
}