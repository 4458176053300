
import React, { useState, useEffect, useRef }  from "react"
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import Papa from "papaparse"
import BulkModal from "../subViews/BulkModal";
import { updateEmail } from "firebase/auth";



const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });



export default function BulkCreation() {
    const [fileUploadData, setFileUploadData] = useState([])
    const [formattedData, setFormattedData] = useState([])



    const fileInputRef = useRef(null);



    const handleFileUpload = (e) => {
        let currentTime = new Date().toLocaleString()
        console.log(currentTime)
        const files = e.target.files;
        console.log(files);
        if (files) {
          console.log(files[0]);
          Papa.parse(files[0], {
            header: true,
            complete: function(results) {
              console.log("Finished:", results.data);
              let formArray = results.data.map(elem => {

      
           //Add validation
      
                return (
                  {
                    a: elem.a,
                    b: elem.b,
                    c: elem.c,
                    d: elem.d ?? "",
                    e: elem.e ?? "",
                    f: elem.f ?? "",
                    cat: elem.cat,
                    style: elem.style,
                    batch: elem.batch
                
                  
                
                  }
                )
                
          
              })
              console.log(formArray)

            //   setUploadData(formArray)
            setFileUploadData(formArray)

            formatData(formArray)


              if (fileInputRef.current) {
                fileInputRef.current.value = '';
              }
    
           
            }}
          )
        }
      
      }


    const formatData = (data) => {
        const mappedArray = data.map((elem) => {

            const valueArray = [
                elem.a,
                elem.b,
                elem.c,
                elem.d,
                elem.e,
                elem.f,
            ]
                .filter((elm) => elm !== "")
                .sort((a,b) => Number(a) - Number(b))

            const sizeCheckArray = valueArray.map((elem) => {
                return Number(elem) % 2
            })

            const sizeSet = new Set(sizeCheckArray)

            let size

            if (sizeSet.size === 1) {
                if (sizeSet.has(0)) {
                    size = "Size One"
                } else {
                    size = "Size Two"
                }

            } else {
                size = "mixed"
            }

        

            return (
               {
                skuArray: valueArray,
                cat: elem.cat,
                style: elem.style,
                batch: elem.batch,
                size: size
               }
            )

        })

        const dataArray = mappedArray.map((elem) => {
            let combinedSku 


            if (elem.skuArray.length < 6) {
                combinedSku = elem.skuArray.join("-")

            } else {
                const bufferString = elem.skuArray.join("")
                combinedSku = bufferString.substring(0,12) + "-" + bufferString.substring(12, bufferString.length)
            }
            



            return (
                {
                    components: elem.skuArray,
                    combinedSku: combinedSku,
                    batch: elem.batch,
                    size: elem.size,
                    cat: elem.cat,
                    style: elem.style,
                    status: "pending"
                }
            )
        })

        console.log(dataArray)

        setFormattedData(dataArray)


    }

    const Table = () => {
        const headers = ["Batch", "Sku", "Category", "Style", "Size", "Status"]

        return (
            <table className="styled-table">
                <thead>
                    <tr>
                        {
                          headers.map((elem, i) => {
                                return (
                                    <th key={i}>{elem}</th>
                                )
                          })
                        }
                    </tr>
                </thead>
                <tbody>
                    {formattedData.map((elem, i) => {
                        return (
                            <tr key={i}>
                                <td>
                                    {elem.batch}
                                </td>
                                <td>
                                    {elem.combinedSku}
                                </td>
                                <td>
                                    {elem.cat}
                                </td>
                                <td>
                                    {elem.style}
                                </td>
                                <td>
                                    {elem.size}
                                </td>
                                <td>
                                    {elem.status}
                                </td>
                            </tr>
                        )
                    })}

                </tbody>
            </table>
        )
    }


    const changeStatus = (combinedSku) => {
       
        const updatedData = [...formattedData];

        const index = updatedData.findIndex((elem) => elem.combinedSku === combinedSku)

        updatedData[index] = {...updatedData[index], status: "complete"}
        

        setFormattedData(updatedData);
    };






    return (
        <div style={{ marginLeft: "14vw" }}>


        {fileUploadData.length === 0 ?

        <div style={{marginLeft:"20vw", marginTop:"20vh"}}>
        
        <Button style={{height:"10vh", width:"10vw", marginRight:"15vw"}} component="label" variant="contained" >
                Upload Batch File
            <VisuallyHiddenInput ref={fileInputRef} onChange={handleFileUpload} type="file" accept=".csv" />
        </Button>

        <Button style={{height:"10vh", width:"10vw"}} component="label" variant="contained" >
                Select Batch File

        </Button>

        </div>

        :

        <div>

        <BulkModal changeStatus={changeStatus} formattedData={formattedData} />

        <Table />

        </div>

     


        }
  
        </div>

    )
}